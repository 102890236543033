/* eslint-disable no-underscore-dangle */
import cookies from 'js-cookie';
import { on } from 'delegated-events';
import whenReady from '../../../javascripts/utils/when-ready';

const cookieOptions = { expires: 365, sameSite: 'Strict', secure: true };
const cookieName = 'mtm_consent';
const $consentBanner = document.querySelector('.consent-banner');

let matomoLoaded = false;

const loadMatomo = () => {
  if (matomoLoaded) {
    return;
  }

  const matomoUrl = '//matomo.gruene-fraktion-brandenburg.de/';

  window._paq = window._paq || [];
  window._paq.push(['trackPageView']);
  window._paq.push(['enableLinkTracking']);
  window._paq.push(['setTrackerUrl', `${matomoUrl}matomo.php`]);
  window._paq.push(['setSiteId', '1']);

  const script = document.createElement('script');
  script.async = true;
  script.src = `${matomoUrl}matomo.js`;

  document
    .getElementsByTagName('head')[0]
    .appendChild(script, document.getElementsByTagName('head')[0]);

  matomoLoaded = true;
};

if ($consentBanner) {
  const hasUserAgreed = cookies.get(cookieName);

  if (hasUserAgreed === '1') {
    loadMatomo();
  } else if (hasUserAgreed !== '0') {
    whenReady().then(() => {
      $consentBanner.classList.remove('consent-banner--hidden');
    });
  }
}

on('click', '.js-agree-analytics', () => {
  cookies.set(cookieName, '1', cookieOptions);
  $consentBanner.classList.add('consent-banner--hidden');
  loadMatomo();
});

on('click', '.js-disagree-analytics', () => {
  cookies.set(cookieName, '0', cookieOptions);
  $consentBanner.classList.add('consent-banner--hidden');
});

on('click', '.js-show-consent-banner', () => {
  $consentBanner.classList.remove('consent-banner--hidden');
  $consentBanner.focus();
});
