import { createFocusTrap, FocusTrap } from 'focus-trap';
import { on } from 'delegated-events';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import whenReady from '../../../javascripts/utils/when-ready';

let focusTrap: FocusTrap | null = null;

const createFlyout = (
  $flyout: HTMLElement,
  onDeactivate: CallableFunction | null = null,
): FocusTrap => {
  $flyout.setAttribute('aria-hidden', 'false');

  const $scrollContainer = $flyout.querySelector('.flyout__scroll-container');

  focusTrap = createFocusTrap($flyout, {
    onPostActivate() {
      if ($scrollContainer) {
        disableBodyScroll($scrollContainer);
      }
    },
    onDeactivate() {
      $flyout.setAttribute('aria-hidden', 'true');

      if ($scrollContainer) {
        enableBodyScroll($scrollContainer);
      }
    },
    onPostDeactivate() {
      onDeactivate?.();
    },
    initialFocus: '.flyout__content',
    clickOutsideDeactivates: true,
    returnFocusOnDeactivate: true,
    preventScroll: false,
  });

  return focusTrap;
};

export const closeFlyout = () => {
  focusTrap?.deactivate();
};

on('click', '.js-flyout', (event) => {
  const { currentTarget: $target } = event;
  const { flyout } = $target.dataset;

  if (flyout) {
    const $flyout = document.getElementById(flyout);

    if ($flyout) {
      event.preventDefault();

      focusTrap = createFlyout($flyout);
      focusTrap.activate();
    }
  }
});

on('click', '.js-flyout-close', () => closeFlyout());

whenReady().then(() => {
  const $flyout = document.querySelector<HTMLElement>(
    '.flyout[aria-hidden=false]',
  );

  if ($flyout) {
    focusTrap = createFlyout($flyout);
    focusTrap.activate();
  }
});

export default createFlyout;
