import h from 'hyperscript';
import Draggabilly from 'draggabilly';
import scroll from 'scroll';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const closestNumber = (needle, haystack) =>
  haystack.reduce((a, b) =>
    Math.abs(b - needle) < Math.abs(a - needle) ? b : a,
  );

const $$scroll = document.querySelectorAll('.js-scroll');
$$scroll.forEach(($scroll) => {
  let enableScrollbarUpdates = true;

  // Get configuration
  const {
    scrollAreaSelector,
    scrollBarContainer,
    scrollItemSelector,
    scrollSpacerSelector = null,
  } = $scroll.dataset;

  // Get target
  const $scrollArea = $scroll.querySelector(scrollAreaSelector);

  // Build scrollbar
  const $thumb = h('div.scroll-bar__thumb');
  const $backdrop = h('div.scroll-bar__backdrop');
  const $scrollBar = h(
    'div.scroll-bar',
    h('div.scroll-bar__track', $backdrop, $thumb),
  );

  // Append scroll bar
  $scroll.querySelector(scrollBarContainer).appendChild($scrollBar);

  const updateScrollBarWidth = () => {
    const width = ($scrollArea.offsetWidth / $scrollArea.scrollWidth) * 100;
    $thumb.style.width = `${width}%`;

    if (width >= 100) {
      $scrollBar.classList.remove('scroll-bar--visible');
      $scroll.classList.remove('has-scrollbar');
    } else {
      $scrollBar.classList.add('scroll-bar--visible');
      $scroll.classList.add('has-scrollbar');
    }
  };

  $backdrop.addEventListener('click', (event) => {
    const p =
      (event.layerX / $scrollBar.parentNode.offsetWidth) *
      $scrollArea.scrollWidth;
    $scrollArea.scrollLeft = p;
  });

  window.addEventListener('resize', updateScrollBarWidth);

  $scrollArea.addEventListener(
    'scroll',
    () => {
      if (enableScrollbarUpdates) {
        $thumb.style.left = `${
          ($scrollArea.scrollLeft / $scrollArea.scrollWidth) * 100
        }%`;
      }
    },
    {
      passive: true,
    },
  );

  const draggie = new Draggabilly($thumb, {
    axis: 'x',
    containment: true,
  });

  // Get teaser positions
  let snappedPosition = 0;
  let teaserPositions = [];
  let maxScrollLeft = 0;

  const $spacer = scrollSpacerSelector
    ? $scrollArea.querySelector(scrollSpacerSelector)
    : null;

  draggie.on('dragStart', () => {
    enableScrollbarUpdates = false;
    $scrollArea.classList.add('is-dragging');
    maxScrollLeft = $scrollArea.scrollWidth - $scrollArea.offsetWidth;

    teaserPositions = [];
    const spacerWidth = $spacer ? $spacer.offsetWidth : 0;

    $scrollArea.querySelectorAll(scrollItemSelector).forEach(($el) => {
      teaserPositions.push($el.offsetLeft - spacerWidth);
    });
  });

  draggie.on('dragMove', function dragMove() {
    // Update position in real time
    const scrollLeft =
      (this.position.x / $backdrop.offsetWidth) * $scrollArea.scrollWidth;
    $scrollArea.scrollBy(scrollLeft - $scrollArea.scrollLeft, 0);

    // Get next snapped position, limited the maximal scroll left value
    snappedPosition = closestNumber(scrollLeft, teaserPositions);
    if (scrollLeft >= maxScrollLeft * 0.9 || snappedPosition >= maxScrollLeft) {
      snappedPosition = maxScrollLeft;
    }
  });

  draggie.on('dragEnd', () => {
    // In Safari we must enabled snapping now
    if (isSafari) {
      $scrollArea.classList.remove('is-dragging');
    }

    // Scroll to snap point
    scroll.left($scrollArea, snappedPosition, () => {
      $scrollArea.classList.remove('is-dragging');
      enableScrollbarUpdates = true;
    });

    // Move thumb to snap point
    window.requestAnimationFrame(() => {
      if ($thumb.animate) {
        $thumb.animate(
          [
            { left: $thumb.style.left },
            { left: `${(snappedPosition / $scrollArea.scrollWidth) * 100}%` },
          ],
          {
            duration: 200,
          },
        );
      }

      $thumb.style.left = `${
        (snappedPosition / $scrollArea.scrollWidth) * 100
      }%`;
    });
  });

  // Focus catcher
  $scrollArea.addEventListener('focusin', (event) => {
    const { target: $target } = event;

    if ($target !== $scrollArea) {
      // Disable focus for now
      event.preventDefault();

      const $teaser = $target.closest(scrollItemSelector);
      const spacerWidth = $spacer ? $spacer.offsetWidth : 0;

      $scrollArea.scrollLeft = $teaser.offsetLeft - spacerWidth;
    }
  });

  // Set initial scroll bar width
  updateScrollBarWidth();
  $thumb.style.left = `${
    ($scrollArea.scrollLeft / $scrollArea.scrollWidth) * 100
  }%`;

  // Update on resize
  const resizeObserver = new ResizeObserver(() => {
    updateScrollBarWidth();
    $thumb.style.left = `${
      ($scrollArea.scrollLeft / $scrollArea.scrollWidth) * 100
    }%`;
  });

  resizeObserver.observe($scrollArea);
});
