import whenReady from '../../../javascripts/utils/when-ready';

whenReady().then(async () => {
  const $$flipbook = document.querySelectorAll<HTMLElement>('.flipbook');

  if ($$flipbook.length > 0) {
    const { default: createFlipbook } = await import(
      /* webpackChunkName: "flipbook" */ './flipbook'
    );

    $$flipbook.forEach(($flipbook) => {
      const $container = $flipbook.querySelector<HTMLElement>(
        '.flipbook__flipbook',
      );

      if (!$container) {
        return;
      }

      const flipbook = createFlipbook($container, '.flipbook__flipbook-page');

      const $prevButton = $flipbook.querySelector<HTMLButtonElement>(
        '.flipbook__navigation-button--prev',
      );

      $prevButton?.addEventListener('click', () => {
        flipbook.flipPrev();
      });

      const $nextButton = $flipbook.querySelector<HTMLButtonElement>(
        '.flipbook__navigation-button--next',
      );

      $nextButton?.addEventListener('click', () => {
        flipbook.flipNext();
      });

      if (flipbook.getPageCount() > 0) {
        $nextButton?.removeAttribute('disabled');
      }

      flipbook.on('flip', (event) => {
        if (event.data === 0) {
          $prevButton?.setAttribute('disabled', 'disabled');
        } else {
          $prevButton?.removeAttribute('disabled');
        }

        if (event.data === flipbook.getPageCount() - 1) {
          $nextButton?.setAttribute('disabled', 'disabled');
        } else {
          $nextButton?.removeAttribute('disabled');
        }
      });
    });
  }
});
