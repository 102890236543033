import { type FocusTrap } from 'focus-trap';
import request from '../../../javascripts/utils/request';
import whenReady from '../../../javascripts/utils/when-ready';
import createFlyout, { closeFlyout } from '../flyout/flyout';
import { type Pin } from './types';

let mapFlyout: FocusTrap | null = null;

const showMapFlyout = async (pin: Pin, $template: HTMLTemplateElement) => {
  // Close other flyouts
  closeFlyout();

  // Create new flyout
  const $templateSource = $template.content.cloneNode(true) as DocumentFragment;
  const $flyout = $templateSource.querySelector<HTMLElement>('.flyout');

  if (!$flyout) {
    return;
  }

  const parentNode = document.getElementById('flyouts') ?? document.body;
  parentNode.appendChild($flyout);

  // Open flyout
  mapFlyout = createFlyout($flyout, () => {
    parentNode.removeChild($flyout);
  });

  mapFlyout.activate();

  // Get flyout content
  const response = await fetch(pin.overlay, {
    method: 'GET',
    credentials: 'same-origin',
  });

  const $flyoutContent = $flyout.querySelector('.flyout__content');

  if ($flyoutContent) {
    $flyoutContent.innerHTML = await response.text();
  }
};

whenReady().then(async () => {
  const $$map = document.querySelectorAll<HTMLElement>('.map');

  if ($$map.length > 0) {
    const { default: createMap, loadPins } = await import(
      /* webpackChunkName: "map" */ './createMap'
    );

    $$map.forEach(async ($map) => {
      const {
        mapStyle: styleEndpoint,
        mapPins: mapsEndpoint,
        mapPinImage: pinImageUrl,
      } = $map.dataset;

      const useFlyouts = $map.hasAttribute('data-map-use-overlay');
      const $mapContainer = $map.querySelector<HTMLElement>('.map__map');
      const $flyoutTemplate = $map.querySelector<HTMLTemplateElement>(
        '.map__flyout-template',
      );

      if (styleEndpoint && $mapContainer) {
        const map = await createMap(styleEndpoint, $mapContainer);

        if (mapsEndpoint && pinImageUrl) {
          const pins = await request<Pin[]>(mapsEndpoint, {
            method: 'GET',
            credentials: 'same-origin',
          });

          loadPins(
            map,
            pins,
            pinImageUrl,
            useFlyouts && $flyoutTemplate
              ? (pin) => showMapFlyout(pin, $flyoutTemplate)
              : false,
          );
        }
      }
    });
  }
});
