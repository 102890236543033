/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import whenReady from '../utils/when-ready';

whenReady().then(() => {
  // states
  const DrawState = {
    IDLE: 0,
    DIRTY_CONTENT: 1,
    DIRTY_LAYOUT: 2,
    DIRTY: 3,
  };

  // all active fitty elements
  const fitties = [];

  const markAsClean = (f) => (f.dirty = DrawState.IDLE);

  const calculateStyles = (f) => {
    // get available width/height from parent node
    f.availableWidth = f.element.parentNode.clientWidth;
    f.availableHeight = f.element.parentNode.clientHeight;

    // the space our target element uses
    f.currentWidth = f.element.scrollWidth;
    f.currentHeight = f.element.scrollHeight;

    // remember current font size
    f.previousFontSize = f.currentFontSize;

    // Width
    const fontSizeWidth = Math.min(
      Math.max(
        f.minSize,
        (f.availableWidth / f.currentWidth) * f.previousFontSize,
      ),
      f.maxSize,
    );

    // Height
    const fontSizeHeight = Math.min(
      Math.max(
        f.minSize,
        (f.availableHeight / f.currentHeight) * f.previousFontSize,
      ),
      f.maxSize,
    );

    // let's calculate the new font size
    switch (f.direction) {
      case 'both':
        f.currentFontSize = Math.min(fontSizeWidth, fontSizeHeight);
        break;

      case 'height':
        f.currentFontSize = fontSizeHeight;
        break;

      case 'width':
      default:
        f.currentFontSize = fontSizeWidth;
        break;
    }

    // if allows wrapping, only wrap when at minimum font size (otherwise would break container)
    f.whiteSpace =
      f.multiLine && f.currentFontSize === f.minSize ? 'normal' : 'nowrap';
  };

  // should always redraw if is not dirty layout, if is dirty layout only redraw if size has changed
  const shouldRedraw = (f) =>
    f.dirty !== DrawState.DIRTY_LAYOUT ||
    (f.dirty === DrawState.DIRTY_LAYOUT &&
      f.element.parentNode.clientWidth !== f.availableWidth);

  // every fitty element is tested for invalid styles
  const computeStyle = (f) => {
    // get style properties
    const style = window.getComputedStyle(f.element, null);

    // get current font size in pixels (if we already calculated it, use the calculated version)
    f.currentFontSize = parseFloat(style.getPropertyValue('font-size'));

    // get display type and wrap mode
    f.display = style.getPropertyValue('display');
    f.whiteSpace = style.getPropertyValue('white-space');
  };

  // determines if this fitty requires initial styling, can be prevented by applying
  // correct styles through CSS
  const shouldPreStyle = (f) => {
    let preStyle = false;

    // if we already tested for prestyling we don't have to do it again
    if (f.preStyleTestCompleted) return false;

    // should have an inline style, if not, apply
    if (!/inline-/.test(f.display)) {
      preStyle = true;
      f.display = 'inline-block';
    }

    // to correctly calculate dimensions the element should have whiteSpace set to nowrap
    if (f.whiteSpace !== 'nowrap') {
      preStyle = true;
      f.whiteSpace = 'nowrap';
    }

    // we don't have to do this twice
    f.preStyleTestCompleted = true;

    return preStyle;
  };

  // apply styles to single fitty
  const applyStyle = (f) => {
    f.element.style.whiteSpace = f.whiteSpace;
    f.element.style.display = f.display;
    f.element.style.fontSize = `${f.currentFontSize}px`;
  };

  // redraws fitties so they nicely fit their parent container
  const redraw = ($el) => {
    // Getting info from the DOM at this point should not trigger a reflow,
    // let's gather as much intel as possible before triggering a reflow

    // check if styles of all fitties have been computed
    $el
      .filter((f) => !f.styleComputed)
      .forEach((f) => {
        f.styleComputed = computeStyle(f);
      });

    // restyle elements that require pre-styling, this triggers a reflow, please try
    // to prevent by adding CSS rules (see docs)
    $el.filter(shouldPreStyle).forEach(applyStyle);

    // we now determine which fitties should be redrawn
    const fittiesToRedraw = $el.filter(shouldRedraw);

    // we calculate final styles for these fitties
    fittiesToRedraw.forEach(calculateStyles);

    // now we apply the calculated styles from our previous loop
    fittiesToRedraw.forEach((f) => {
      applyStyle(f);
      markAsClean(f);
    });
  };

  // Group all redraw calls till next frame, we cancel each frame request when a new one
  // comes in. If no support for request animation frame, this is an empty function and
  // supports for fitty stops.
  let redrawFrame = null;
  const requestRedraw = () => {
    window.cancelAnimationFrame(redrawFrame);
    redrawFrame = window.requestAnimationFrame(() =>
      redraw(fitties.filter((f) => f.dirty && f.active)),
    );
  };

  // sets all fitties to dirty so they are redrawn on the next redraw loop, then calls redraw
  const redrawAll = (type) => () => {
    fitties.forEach((f) => (f.dirty = type));
    requestRedraw();
  };

  // fit method, marks the fitty as dirty and requests a redraw (this will also redraw any
  // other fitty marked as dirty)
  const fit = (f, type) => () => {
    f.dirty = type;
    if (!f.active) return;
    requestRedraw();
  };

  const observeMutations = (f) => {
    // no observing?
    if (!f.observeMutations) return;

    // start observing mutations
    f.observer = new MutationObserver(fit(f, DrawState.DIRTY_CONTENT));

    // start observing
    f.observer.observe(f.element, f.observeMutations);
  };

  // default mutation observer settings
  const mutationObserverDefaultSetting = {
    subtree: true,
    childList: true,
    characterData: true,
  };

  // set options object
  const fittyOptions = {
    minSize: 16,
    maxSize: 512,
    multiLine: true,
    observeMutations: mutationObserverDefaultSetting,
  };

  document.querySelectorAll('.js-fit-text').forEach(($el) => {
    // create fitty instance
    const f = {
      ...fittyOptions,
      element: $el,
      active: true,
      direction: $el.dataset.direction || 'width',
    };

    // save some of the original CSS properties before we change them
    f.originalStyle = {
      whiteSpace: f.element.style.whiteSpace,
      display: f.element.style.display,
      fontSize: f.element.style.fontSize,
    };

    // should we observe DOM mutations
    observeMutations(f);

    // this is a new fitty so we need to validate if it's styles are in order
    f.newbie = true;

    // because it's a new fitty it should also be dirty, we want it to redraw on the first loop
    f.dirty = true;

    // we want to be able to update this fitty
    fitties.push(f);
  });

  requestRedraw();

  // handles viewport changes, redraws all fitties, but only does so after a timeout
  let resizeDebounce = null;
  const onWindowResized = () => {
    window.clearTimeout(resizeDebounce);
    resizeDebounce = window.setTimeout(redrawAll(DrawState.DIRTY_LAYOUT), 100);
  };

  // define observe window property, so when we set it to true or false events are
  // automatically added and removed
  const events = ['resize', 'orientationchange'];
  events.forEach((e) => {
    window.addEventListener(e, onWindowResized);
  });
});
